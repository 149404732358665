import { stringEnum } from 'common/utils/objects';
import { BaseDbDoc, EnumValues, Languages } from 'common/types';
import { Timestamp } from 'common/frontend/firebase/firestore';
export interface UserApi extends BaseDbDoc {
	shops: {
		[shopId: string]: boolean;
	};
	activeShopId: string;
	firstName?: string;
	lastName?: string;
	language?: Languages;
	email: string;
	access: ShopAccess;
	accessUpdated?: Timestamp;
}

export const ShopRoles = stringEnum(['admin', 'manager', 'editor', 'member']);
export const RentleSupportRoles = stringEnum(['rentle_support']);
export const Roles = { ...ShopRoles, ...RentleSupportRoles };

export const Permissions = stringEnum(['modify_payments', 'allow_overbookings']);
export type ShopRole = EnumValues<typeof ShopRoles>;
export type RentleSupportRole = EnumValues<typeof RentleSupportRoles>;
export type Role = ShopRole | RentleSupportRole;
export type Permission = EnumValues<typeof Permissions>;
export type RolePermission = Role | Permission;

export interface AccessClaims {
	roles: Role[];
	permissions: Permission[];
	locations?: string[];
}
export interface ShopAccess {
	[shopId: string]: AccessClaims;
}
