import { Api } from 'common/db/api/paths';

export const bulkItemBySkuId = async (
	api: Api,
	args: { shopId: string; skuId: string; locationId: string },
) => {
	const { shopId, skuId, locationId } = args;
	const items = await api.inventoryItems.bulkItems
		.where('shopId', '==', shopId)
		.where('locationId', '==', locationId)
		.where('skuId', '==', skuId)
		.get();
	// Bulk item connected to single sku in single location is always unique, , so we know there is always a single result
	return items[0];
};

export const singleItemByCode = async (
	api: Api,
	args: {
		shopId: string;
		code: string | null;
	},
) => {
	const { shopId, code } = args;
	if (!code) return;
	const inventoryItems = await api.inventoryItems.singleItems
		.where('shopId', '==', shopId)
		.whereArray('identifiers', 'array-contains', code)
		.get();
	// Item identifier codes are always unique, so we know there is always a single result
	return inventoryItems[0];
};
