import { BrowserInfo, EnumValues, Environment } from 'common/types';

export const StorageKeys = {
	LANG: 'lang',
	PAYMENT_METHOD: 'activePaymentMethod',
	EXTERNAL_HOME_URL: 'externalHomeUrl',
	EMBED_REDIRECT_PATH: 'embedRedirectPath',
	RESERVATION_ID: 'reservationId',
	COOKIE_CONSENTS: 'cookieConsents',
	EXT_AUTH_TOKEN: 'extAuthToken',
	EXT_AUTH_TOKEN_EXPIRY: 'extAuthTokenExpiry',
	AFFILIATE: 'affiliate',
} as const;

type StorageKey = EnumValues<typeof StorageKeys>;

export const Storages = {
	LOCAL: 'localStorage',
	SESSION: 'sessionStorage',
} as const;
type StorageType = EnumValues<typeof Storages>;

declare global {
	interface Window {
		storage: {
			lang?: string | null;
		};
	}
}

export const getQueryParam = (paramName: string) => {
	const regex = new RegExp('[?&]' + paramName + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(window.location.search);
	if (!results) {
		return null;
	}
	if (!results[2]) {
		return '';
	}
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const getHashParam = (paramName: string) => {
	const regex = new RegExp('[#&=]' + paramName + '(=([^&#]*)|&|#|$)');
	const results = regex.exec(decodeURIComponent(window.location.hash));
	if (!results) {
		return null;
	}
	if (!results[2]) {
		return '';
	}
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const storageAvailable = (type: StorageType) => {
	try {
		const storage = window[type];
		const x = '__storage_test__';
		storage.setItem(x, x);
		storage.removeItem(x);
		return true;
	} catch (e) {
		return false;
	}
};

export const saveToStorage = (type: StorageType, key: StorageKey, value: string) => {
	if (storageAvailable(type)) {
		const storage = window[type];
		storage.setItem(key, value);
	} else {
		if (!window.storage) {
			window.storage = {};
		}
		window.storage[key] = value;
	}
};

export const getFromStorage = (type: StorageType, key: StorageKey): string | null => {
	if (storageAvailable(type)) {
		const storage = window[type];
		return storage.getItem(key);
	} else {
		return window.storage?.[key] ?? null;
	}
};

export const removeFromStorage = (type: StorageType, key: StorageKey) => {
	if (storageAvailable(type)) {
		const storage = window[type];
		return storage.removeItem(key);
	} else {
		if (!window.storage) return;
		delete window.storage[key];
	}
};

export const clearStorage = (type: StorageType) => {
	if (storageAvailable(type)) {
		const storage = window[type];
		storage.clear();
	} else {
		if (!window.storage) return;
		for (const prop of Object.keys(window.storage)) {
			delete (window.storage as any)[prop];
		}
	}
};

export const getCheckoutOriginKey = (origin: string) => {
	if (origin === 'http://localhost:4000' || origin === 'http://localhost:4001') {
		return 'pub.v2.8015587002460981.aHR0cDovL2xvY2FsaG9zdDo0MDAw.CkwRX1ELymM-MptEIajcqSSuQJm5c4_lVMwA1OrYJmM';
	}
	if (origin === 'https://dev.rentle.shop') {
		return 'pub.v2.8015587002460981.aHR0cHM6Ly9kZXYucmVudGxlLnNob3A.BDAnOD-zGK_kn-11qYbXIq0EWNN-Sj6VR6m25pWKiSE';
	}
	if (origin === 'https://rentle.dev') {
		return 'pub.v2.8015587002460981.aHR0cHM6Ly9yZW50bGUuZGV2.mFdGwL9232d3i_8lhLY0Q4jFHXMfunpCpiEIn8xJ-Ns';
	}
	if (origin === 'https://toomas.rentle.dev') {
		return 'pub.v2.8015587002460981.aHR0cHM6Ly90b29tYXMucmVudGxlLmRldg.F6kf2MNsBx_cJbBPgLXr9phcxMjoWV1KYL5zEfepgI4';
	}
	if (origin === 'https://online-dot-rentle-toomas.appspot.com') {
		return 'pub.v2.8015587002460981.aHR0cHM6Ly9vbmxpbmUtZG90LXJlbnRsZS10b29tYXMuYXBwc3BvdC5jb20.ujcVkHc4j5zvkwaFAtRo8lDBq-l-RkBG0kPJNHb3Lis';
	}
	// live
	if (origin === 'https://rentle.shop') {
		return 'pub.v2.4115815220230912.aHR0cHM6Ly9yZW50bGUuc2hvcA.QN9TpNNmIHoM43kGD0X7samEKHFXnEkmn_hvQnde5sY';
	}
	if (origin === 'https://beta.rentle.shop') {
		return 'pub.v2.4115815220230912.aHR0cHM6Ly9iZXRhLnJlbnRsZS5zaG9w.UpB2PJGrpkwZUD2kXrulbBQPwg690OzcC8967-i7Z3I';
	}
	return 'pub.v2.4115815220230912.aHR0cHM6Ly9yZW50bGUuc2hvcA.QN9TpNNmIHoM43kGD0X7samEKHFXnEkmn_hvQnde5sY';
};

export const getClientKey = (env: 'development' | 'production') => {
	if (env === 'production') {
		return 'live_63RKEIRHL5G2JIOPQAU6XUYF2I2HYASW';
	}
	return 'test_4EEAX3QAENDYJANXJS6KNZGIDUFA2NMN';
};

export const getBrowserInfo = (window: Window): BrowserInfo => {
	return {
		userAgent: '',
		acceptHeader: '',
		language: window.navigator.language,
		colorDepth: window.screen.colorDepth,
		screenHeight: window.screen.height,
		screenWidth: window.screen.width,
		timeZoneOffset: new Date().getTimezoneOffset(),
		javaEnabled: window.navigator.javaEnabled(),
	};
};

export const getClientEnv = (): Environment => {
	const env = process.env.REACT_APP_ENV;
	return env === 'local' ? 'local' : env === 'development' ? 'development' : 'production';
};
