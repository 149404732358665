import { stringEnum } from 'common/utils/objects';

export const USER_DIDNT_INPUT_PHOTO = 'USER_DIDNT_INPUT_PHOTO';

export const DTAImageTypes = stringEnum(['Jpeg', 'Bitmap']);
export type DTAImageType = keyof typeof DTAImageTypes;

export const PhotoUploaded = stringEnum([USER_DIDNT_INPUT_PHOTO, 'UPLOADED', 'ERROR']);
export type PhotoUploadedType = keyof typeof PhotoUploaded;

export const SkidataTicketTimePeriods = stringEnum([
	'ns3:OrTimePeriodNode',
	'ns3:AndTimePeriodNode',
	'ns3:TimeRange',
	'ns3:DailyTimePeriodSeries',
	'ns3:NotTimePeriodNode',
	'ns3:MonthlyTimePeriodSeries',
	'ns3:WeekdayTimePeriodSeries',
]);
export type SkidataTicketTimePeriodType = keyof typeof SkidataTicketTimePeriods;
