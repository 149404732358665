import { createTheme, alpha, ThemeOptions, Theme } from '@mui/material/styles';

import * as Colors from './colors';
import * as Fonts from './fonts';
import { getOverrides } from './overrides';

//mui4 -> mui5 migration related module declaration, more info https://mui.com/guides/migration-v4/#mui-styles
declare module '@mui/styles/defaultTheme' {
	// eslint-disable-next-line @typescript-eslint/no-empty-interface
	interface DefaultTheme extends Theme {}
}

const Base: Partial<ThemeOptions> = {
	breakpoints: {
		keys: ['xs', 'sm', 'md', 'lg', 'xl'],
		values: {
			xs: 0,
			sm: 600,
			md: 960,
			lg: 1280,
			xl: 1920,
		},
	},
	direction: 'ltr',
	mixins: {
		toolbar: {
			minHeight: 56,
			'@media (min-width:0px) and (orientation: landscape)': {
				minHeight: 48,
			},
			'@media (min-width:600px)': {
				minHeight: 64,
			},
		},
	},
	typography: {
		fontFamily: Fonts.inter,
		htmlFontSize: 10,
		allVariants: {
			fontWeight: 400,
		},
		h1: {
			fontWeight: 500,
		},
		h2: {
			fontWeight: 500,
		},
		h3: {
			fontWeight: 500,
		},
		h4: {
			fontWeight: 500,
		},
		h5: {
			fontWeight: 500,
		},
		h6: {
			fontWeight: 500,
		},
		button: {
			fontWeight: 700,
		},
		caption: {
			fontWeight: 500,
		},
		body2: {
			fontWeight: 400,
		},
	},
	shape: {
		borderRadius: 4,
	},
	spacing: 8,
};

/**
 * Base, white-labelable light theme (online, etc.)
 */
export const Light = createTheme({
	...Base,
	typography: {
		...Base.typography,
		subtitle2: {
			fontWeight: 400,
		},
	},
	palette: Colors.neutralLight,
	components: getOverrides({
		extras: {
			MuiFab: {
				styleOverrides: {
					root: {
						borderRadius: 0,
					},
					extended: {
						borderRadius: 0,
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 0,
					},
				},
			},
		},
	}),
});

/**
 * Base, white-labelable dark theme (online, etc.)
 */
export const Dark = createTheme({
	...Base,
	palette: Colors.paletteDark,
	components: getOverrides(),
});

/**
 * Rentle-branded light theme (consumer app etc.)
 */
export const RentleLight = createTheme({
	...Base,
	palette: Colors.neutralLight,
	components: getOverrides({
		extras: {
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: '17.5px',
						'&.Mui-disabled': {
							backgroundColor: alpha('#ffffff', 0.8),
						},
					},
					containedSizeLarge: {
						borderRadius: '3px',
						minHeight: '51px',
						fontSize: '18px',
					},
				},
			},
			MuiLink: {
				styleOverrides: {
					button: {
						textTransform: 'uppercase',
						textDecoration: 'underline',
						fontSize: '12px',
					},
				},
			},
		},
	}),
});

/**
 * Rentle-branded dark theme (consumer app etc.)
 */

export const RentleDark = createTheme({
	...Base,
	palette: Colors.paletteDark,
	components: getOverrides({
		extras: {
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: '17.5px',
						'&.Mui-disabled': {
							backgroundColor: alpha('#ffffff', 0.8),
						},
					},
					containedSizeLarge: {
						borderRadius: '3px',
						minHeight: '51px',
						fontSize: '18px',
					},
				},
			},
			MuiLink: {
				styleOverrides: {
					button: {
						textTransform: 'uppercase',
						textDecoration: 'underline',
						fontSize: '12px',
					},
				},
			},
			MuiTypography: {
				styleOverrides: {
					h1: {
						fontFamily: Fonts.circular,
					},
					h2: {
						fontFamily: Fonts.circular,
					},
				},
			},
		},
	}),
});

/**
 * Admin light theme
 */

export const AdminLight = createTheme({
	...Base,
	palette: Colors.paletteLight,
	components: getOverrides({
		extras: {
			MuiIconButton: {
				styleOverrides: {
					root: {
						color: Colors.pru.main,
					},
				},
			},
			MuiButton: {
				styleOverrides: {
					root: {
						borderRadius: 50,
						minWidth: 112,
						minHeight: 40,
						fontWeight: 700,
					},
					contained: {
						'&.Mui-disabled': {
							color: '#fff',
						},
					},
				},
			},
			MuiButtonGroup: {
				styleOverrides: {
					root: {
						borderRadius: 50,
						minWidth: 112,
						minHeight: 40,
						fontWeight: 700,
					},
					contained: {
						'&.Mui-disabled': {
							color: '#fff',
						},
					},
				},
			},
			MuiTableCell: {
				styleOverrides: {
					root: {
						borderBottom: '1px solid #d8d8d8',
					},
				},
			},
			MuiTypography: {
				styleOverrides: {
					h1: {
						fontFamily: Fonts.circular,
					},
					h2: {
						fontFamily: Fonts.circular,
					},
				},
			},
			MuiPaper: {
				styleOverrides: {
					root: {
						boxShadow: '0px 2px 6px rgba(163, 163, 163, 0.5)',
					},
					elevation0: {
						boxShadow: 'none',
					},
				},
			},
		},
	}),
});
