import {
	AdyenPaymentMethod,
	AdyenMethodType,
	RecurringPaymentMethod,
	NonRecurringPaymentMethod,
	ManualDepositPaymentMethod,
	NonDepositPaymentMethod,
	AutomaticDepositPaymentMethod,
} from './types';
import { stringEnum } from 'common/utils/objects';

export const AdyenMethods = stringEnum([
	'CARD_ONLINE',
	'TRUSTLY',
	'PAYTRAIL',
	'MOBILEPAY',
	'APPLEPAY',
	'GOOGLEPAY',
]);

export const StripeMethods = stringEnum([
	'CARD_ONLINE_STRIPE',
	// 'APPLEPAY_STRIPE',
	// 'GOOGLEPAY_STRIPE',
]);

export const ExternalMethods = stringEnum(['EPASSI_ONLINE']);

export const ManualMethods = stringEnum(['PAY_STORE']);

export const WalletMethods = stringEnum(['APPLEPAY', 'GOOGLEPAY']);

export const RecurringMethods = stringEnum(['CARD_ONLINE']);

export const AutomaticDepositMethods = stringEnum(['CARD_ONLINE']);

export const ManualDepositMethods = stringEnum(['PAY_STORE', 'MANUAL']);

export const DepositMethods = { ...AutomaticDepositMethods, ...ManualDepositMethods };

export const CustomManualMethodId = stringEnum(['MANUAL']);

export const OnlineMethods = {
	...AdyenMethods,
	...ExternalMethods,
	...ManualMethods,
	...StripeMethods,
};

export const OnlineProviderMethods = {
	...AdyenMethods,
	...StripeMethods,
};

export const OnlineMethodsByProvider = {
	ADYEN: AdyenMethods,
	EXTERNAL: ExternalMethods,
	MANUAL: ManualMethods,
	STRIPE: StripeMethods,
} as const;

export const AdyenMethodTypes = stringEnum([
	'scheme',
	'trustly',
	'ebanking_FI',
	'mobilepay',
	'applepay',
	'paywithgoogle',
]);

export const PAYMENT_METHOD_TO_ADYEN_TYPE_MAP: Record<AdyenPaymentMethod, AdyenMethodType> = {
	CARD_ONLINE: 'scheme',
	TRUSTLY: 'trustly',
	PAYTRAIL: 'ebanking_FI',
	MOBILEPAY: 'mobilepay',
	APPLEPAY: 'applepay',
	GOOGLEPAY: 'paywithgoogle',
} as const;

interface PaymentMethodLimitation_BASE {
	countries?: string[];
	currencies?: string[];
	recurring: boolean;
	externalRedirect?: boolean;
	disabledInIframe?: boolean;
	rentleDomainOnly?: boolean;
	deposits: false | 'manual' | 'automatic';
}

interface RecurringPaymentMethodLimitation extends PaymentMethodLimitation_BASE {
	recurring: true;
}

interface NonRecurringPaymentMethodLimitation extends PaymentMethodLimitation_BASE {
	recurring: false;
}

interface ManualDepositPaymentMethodLimitation extends PaymentMethodLimitation_BASE {
	deposits: 'manual';
}

interface AutomaticDepositPaymentMethodLimitation extends PaymentMethodLimitation_BASE {
	deposits: 'automatic';
}
interface NonDepositPaymentMethodLimitation extends PaymentMethodLimitation_BASE {
	deposits: false;
}

export type PaymentMethodLimitationObject =
	| RecurringPaymentMethodLimitation
	| NonRecurringPaymentMethodLimitation
	| ManualDepositPaymentMethodLimitation
	| AutomaticDepositPaymentMethodLimitation
	| NonDepositPaymentMethodLimitation;

export const ONLINE_PAYMENT_METHOD_LIMITATIONS: Record<
	RecurringPaymentMethod,
	RecurringPaymentMethodLimitation
> &
	Record<ManualDepositPaymentMethod, ManualDepositPaymentMethodLimitation> &
	Record<AutomaticDepositPaymentMethod, AutomaticDepositPaymentMethodLimitation> &
	Record<NonRecurringPaymentMethod, NonRecurringPaymentMethodLimitation> &
	Record<NonDepositPaymentMethod, NonDepositPaymentMethodLimitation> = {
	CARD_ONLINE: {
		recurring: true,
		deposits: 'automatic',
		externalRedirect: true,
	},
	TRUSTLY: {
		recurring: false,
		currencies: ['EUR', 'SEK', 'NOK', 'DKK'],
		countries: ['FI', 'SE', 'NO', 'DK'],
		externalRedirect: true,
		deposits: false,
	},
	PAYTRAIL: {
		recurring: false,
		currencies: ['EUR'],
		countries: ['FI'],
		externalRedirect: true,
		deposits: false,
	},
	MOBILEPAY: {
		recurring: false,
		currencies: ['EUR', 'DKK'],
		countries: ['FI', 'DK'],
		deposits: false,
	},
	APPLEPAY: {
		recurring: false,
		disabledInIframe: true,
		deposits: false,
	},
	GOOGLEPAY: {
		recurring: false,
		deposits: false,
		rentleDomainOnly: true,
	},
	EPASSI_ONLINE: {
		recurring: false,
		currencies: ['EUR'],
		countries: ['FI'],
		deposits: false,
	},
	PAY_STORE: {
		recurring: false,
		deposits: 'manual',
	},
	CARD_ONLINE_STRIPE: {
		recurring: false,
		deposits: false,
	},
	MANUAL: {
		recurring: false,
		deposits: 'manual',
	},
};
