/// <reference types="@adyen/api-library/lib/src/typings/checkout" />
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'common/frontend/firebase/init';
import 'common/services/errorHandler';

import React, { Suspense } from 'react';
import * as ReactDOM from 'react-dom';
import { Route, Switch, Router } from 'react-router-dom';

import { CssBaseline, MuiThemeProvider } from '@material-ui/core';
import history from 'services/history';
import { unregister } from 'services/serviceWorker';
import Spinner from 'common/components/Spinner';
import * as Themes from 'common/styles/themes';
import AppRouter from './AppRouter';
unregister();

history.listen(() => {
	// Use setTimeout to make sure this runs after React Router's own listener
	setTimeout(() => {
		window.scrollTo(0, 0);
	});
});

ReactDOM.render(
	<Suspense fallback={<Spinner color="#260178" />}>
		<MuiThemeProvider theme={Themes.Light}>
			<CssBaseline />
			<div id="app" style={{ height: '100%' }}>
				<Router history={history}>
					<Switch>
						<Route path="/" component={AppRouter} />
					</Switch>
				</Router>
			</div>
		</MuiThemeProvider>
	</Suspense>,
	document.getElementById('root') as HTMLElement,
);
