import type { Performance } from './perf';

const TraceMock = {
	start: () => undefined,
	stop: () => undefined,
	record: (
		startTime: number,
		duration: number,
		options?: {
			metrics?: { [key: string]: number };
			attributes?: { [key: string]: string };
		},
	) => undefined,
	incrementMetric: (metricName: string, num?: number) => undefined,
	putMetric: (metricName: string, num: number) => undefined,
	getMetric: (metricName: string) => 0,
	putAttribute: (attr: string, value: string) => undefined,
	getAttribute: (attr: string) => 'mock attribute',
	removeAttribute: (attr: string) => undefined,
	getAttributes: () => ({
		mock: 'mock attributes',
	}),
};

export const FirebasePerformanceMock: Performance = {
	configs: {
		instrumentationEnabled: false,
		dataCollectionEnabled: false,
	},
	trace: (traceName: string) => TraceMock,
	isMock: true,
};
