export const ROOT = '/';

export const SHOP_NAME = '/:shopName';

export const CATEGORIES = '/:shopName/:locationName/categories';
export const CATEGORY_PRODUCTS = '/:shopName/:locationName/categories/:categoryId/products';
export const CATEGORY_SINGLE_PRODUCT =
	'/:shopName/:locationName/categories/:categoryId/products/:productId';

export const ONE_LOCATION_CATEGORY_PRODUCTS = '/:shopName/categories/:categoryId/products';
export const ONE_LOCATION_CATEGORY_SINGLE_PRODUCT =
	'/:shopName/categories/:categoryId/products/:productId';

export const OTHER_PRODUCTS = '/:shopName/:locationName/categories/others/products/';
export const OTHER_SINGLE_PRODUCT =
	'/:shopName/:locationName/categories/others/products/:productId';

export const ONE_LOCATION_OTHER_PRODUCTS = '/:shopName/categories/others/products/';
export const ONE_LOCATION_OTHER_SINGLE_PRODUCT = '/:shopName/categories/others/products/:productId';

export const PRODUCTS = '/:shopName/:locationName/products';
export const SINGLE_PRODUCT = '/:shopName/:locationName/products/:productId';

export const ONE_LOCATION_PRODUCTS = '/:shopName/products';
export const ONE_LOCATION_SINGLE_PRODUCT = '/:shopName/products/:productId';

export const TICKETS = '/:shopName/tickets';

export const SUMMARY = '/:shopName/summary';
export const RESPONSIBLE = '/:shopName/responsible';
export const CANCELLATION = '/:shopName/terms/cancellation';
export const CONFIRMATION = '/:shopName/confirm';
export const CONFIRMED = '/:shopName/confirmed';
export const SHOPPER_DETAILS = '/:shopName/shopperdetails';
export const PERSON = '/:shopName/shopperdetails/person';
export const PERSON_PRODUCTS = '/:shopName/shopperdetails/personproducts';
export const SHOPPER_SUMMARY = '/:shopName/shopperdetails/shopperssummary';
