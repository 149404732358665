import { RemoveFirstFromTuple } from 'common/types/utils';
import {
	getFunctions,
	httpsCallable as _httpsCallable,
	connectFunctionsEmulator as _connectFunctionsEmulator,
} from 'firebase/functions';
import { firebaseApp } from './app';
export type { HttpsCallableOptions } from 'firebase/functions';

export const functions = getFunctions(firebaseApp, 'europe-west1');

export const httpsCallable = <RequestData = unknown, ResponseData = unknown>(
	...paramsWithoutFunctionsInstance: RemoveFirstFromTuple<Parameters<typeof _httpsCallable>>
) => _httpsCallable<RequestData, ResponseData>(functions, ...paramsWithoutFunctionsInstance);

export const connectFunctionsEmulator = () =>
	_connectFunctionsEmulator(functions, 'localhost', 5001);
