import { ManualPaymentMethodDetails } from 'common/modules/payments/types';
import {
	AccountingGroup,
	AccountOwner,
	AdditionalOwner,
	CancellationObject,
	Currency,
	DinCollectionMethod,
	EmailNotification,
	EnumKeys,
	NotificationRecipient,
	ShopRentalType,
	VatTable,
} from 'common/types';
import { stringEnum } from 'common/utils/objects';

export interface ShopApi {
	/**
	 * Shop creation date
	 */
	createdAt: string;
	/**
	 * Currency of the shop
	 */
	currency: Currency;
	/**
	 * Shop ID
	 */
	id: string;
	/**
	 * @deprecated
	 * Is store live or not
	 */
	live: boolean;
	/**
	 * Object containing shop users, [userId]: true means that user can access the shop
	 */
	users: {
		[userId: string]: boolean;
	};
	/**
	 * @deprecated
	 * Is store verified or not
	 */
	verified: boolean;
	/**
	 * @deprecated
	 * Rental types of store
	 */
	rentalTypes?: ShopRentalType[];
	/**
	 * List of shop's saved image urls
	 */
	images?: string[];
	/**
	 * @deprecated
	 * Cancellation policy for shop
	 */
	cancellationPolicy?: CancellationObject[];
	/**
	 * Shop's terms acceptance version and time
	 *
	 * @todo Move under shop read only / admin
	 */
	tosAcceptance: {
		version: number;
		timestamp: string;
	};
	/**
	 * Shop defined VAT values
	 */
	vatTable?: VatTable;
	/**
	 * @deprecated
	 * Show payment state of order - defaults to true on shop creation
	 */
	showPaymentState?: boolean;
	/**
	 * Should receipt be printed when order is started - default false
	 */
	printReceipt?: boolean;

	/**
	 * Toggle if shop uses Rentle POS - requires also POS feature flag
	 */
	useAsPos?: boolean;
	/**
	 * Defined shops custom payment methods
	 *
	 * Relevant only if useAsPos used
	 */
	customPaymentMethods?: string[];

	/**
	 * Manual payment methods defined for the shop
	 */
	manualPaymentMethods?: ManualPaymentMethodDetails[];
	/**
	 * Email notification configurations
	 */
	emailNotifications?: {
		onlineReservations: EmailNotification;
		extensions?: EmailNotification;
		/**
		 * Defines if the email should be sent to merchant email or store email
		 */
		recipient?: NotificationRecipient; // If undefined, you should default to 'store'
	};
	/**
	 * Store incementing receipt number
	 *
	 * @todo Move under shop readOnly / admin
	 */
	receiptNumber?: {
		live: number;
		test: number;
	};
	/**
	 * How DIN is collected for shop
	 *
	 * @todo DIN data should be moved under plugins.din later on
	 */
	dinCollectionMethod?: DinCollectionMethod;
	/**
	 * Shop plugins
	 */
	plugins?: ShopPlugins;
	/**
	 * Incrementing payment number for shop - increases when a payment request is done for order
	 *
	 * Used to keep up which payment requests have been created - especially when working with POS integrations
	 */
	paymentNumber?: number;
	accountOwner?: AccountOwner;
	customDomain?: string | null;
	payoutAccount?: {
		object: 'bank_account';
		country: 'FI';
		currency: 'EUR';
		accountHolderName?: string;
		accountNumber: string;
	};
	additionalOwners?: AdditionalOwner[];
}

export interface ShopPlugins {
	din?: {
		useDinVariation?: boolean;
	};
	customAccountingGroups?: AccountingGroup[];
	cloudPos?: {
		triggerTypes: PosTriggerType[];
	};
	teksoPos?: {
		triggerTypes: PosTriggerType[];
	};
}

export const PosTriggerTypes = stringEnum([
	'onOrderReceiptPrint',
	'onShopperReceiptPrint',
	'externalButton',
]);

export type PosTriggerType = EnumKeys<typeof PosTriggerTypes>;
