import { Environment, ShopUrlApi } from 'common/types';

export const getAdminBaseUrl = (env: Environment) => {
	switch (env) {
		case 'local':
			return `http://localhost:8000`;
		case 'development':
			return `https://dev.my.rentle.shop`;
		case 'production':
		default:
			return `https://my.rentle.shop`;
	}
};

export const getOnlineBaseUrl = (env: Environment) => {
	switch (env) {
		case 'local':
			return `http://localhost:4001`;
		case 'development':
			return `https://dev.rentle.store`;
		case 'production':
		default:
			return `https://rentle.store`;
	}
};

export const getIframeStoreUrl = (args: {
	shopUrlDoc: ShopUrlApi | undefined;
	locationId: string | null;
	env: Environment;
	lang?: string;
}) => {
	const { shopUrlDoc, locationId, env, lang } = args;
	const locationIdLocationUrlMap = Object.entries(shopUrlDoc?.locationUrls ?? {}).reduce(
		(result, [locationName, locationId]) => {
			result[locationId] = locationName;
			return result;
		},
		{} as { [locationId: string]: string },
	);
	const shopUrlPath = shopUrlDoc?.storeUrl ?? '';
	const onlineStoreBaseUrl = `${getOnlineBaseUrl(env)}/${shopUrlPath}`;
	const locationUrlPath = !locationId ? undefined : locationIdLocationUrlMap[locationId];
	const iframeUrl = !locationUrlPath
		? onlineStoreBaseUrl
		: `${onlineStoreBaseUrl}/l/${locationUrlPath}`;
	return !!lang ? `${iframeUrl}?lang=${lang}` : iframeUrl;
};

const getDomainWithHttps = (domainName: string) => {
	return `https://${domainName}`;
};

export const getOnlineStoreUrl = (args: {
	shopUrlDoc: ShopUrlApi | undefined;
	locationId: string | null;
	env: Environment;
}) => {
	const { shopUrlDoc, locationId, env } = args;
	if (!shopUrlDoc) return getOnlineBaseUrl(env);
	const shopUrlPath = shopUrlDoc.storeUrl ?? '';
	const locationIdLocationUrlMap = Object.entries(shopUrlDoc.locationUrls ?? {}).reduce(
		(result, [locationName, locationId]) => {
			result[locationId] = locationName;
			return result;
		},
		{} as { [locationId: string]: string },
	);
	const locationUrlPath = !locationId ? undefined : locationIdLocationUrlMap[locationId];
	const primaryCustomDomain =
		!!shopUrlDoc.primaryCustomDomain &&
		!!shopUrlDoc.customDomains?.includes(shopUrlDoc.primaryCustomDomain)
			? shopUrlDoc.primaryCustomDomain
			: undefined;
	const onlineStoreBaseUrl = !!primaryCustomDomain
		? getDomainWithHttps(primaryCustomDomain)
		: `${getOnlineBaseUrl(env)}/${shopUrlPath}`;
	if (!locationUrlPath) return onlineStoreBaseUrl;
	return `${onlineStoreBaseUrl}/l/${locationUrlPath}`;
};

export const getOnlineStoreLocationBaseUrl = (args: {
	shopUrlDoc: ShopUrlApi | undefined;
	env: Environment;
}) => {
	const onlineStoreBaseUrl = getOnlineStoreUrl({
		...args,
		locationId: null,
	});
	return `${onlineStoreBaseUrl}/l`;
};

export const getOnlineStoreOrderConfirmLink = (args: {
	shopUrlDoc: ShopUrlApi | undefined;
	locationId: string | null;
	env: Environment;
	orderId: string;
}) => {
	const { orderId, ...rest } = args;
	const onlineStoreBaseUrl = getOnlineStoreUrl(rest);
	return `${onlineStoreBaseUrl}/confirm?orderId=${orderId}`;
};

export const getCheckinUrl = (args: {
	shopUrlDoc: ShopUrlApi | undefined;
	locationId: string | null;
	env: Environment;
}) => {
	const { shopUrlDoc, locationId, env } = args;
	const shopUrlPath = shopUrlDoc?.storeUrl ?? '';
	const locationIdLocationUrlMap = Object.entries(shopUrlDoc?.locationUrls ?? {}).reduce(
		(result, [locationName, locationId]) => {
			result[locationId] = locationName;
			return result;
		},
		{} as { [locationId: string]: string },
	);
	const locationUrlPath = !locationId ? undefined : locationIdLocationUrlMap[locationId];
	const checkInBaseUrl =
		env !== 'production' ? 'https://dev.store.rentle.shop' : 'https://store.rentle.shop';
	const baseUrlWithStorePath = `${checkInBaseUrl}/${shopUrlPath}`;
	return !!locationUrlPath ? `${baseUrlWithStorePath}/${locationUrlPath}` : baseUrlWithStorePath;
};
