import { MAX_EXTENSION_SECONDS } from 'common/constants/extensions';
import {
	ExtensionBasePrice,
	ExtensionDuration,
	OrderProduct,
	ProductApi,
	ShopPublicInfo,
	OpeningHoursWithExceptions,
	ExceptionPeriod,
	OpeningHourTimesWithMoments,
	Currency,
} from 'common/types';
import { hashByUniqueField } from 'common/utils/arrays';
import { isRentlePaymentsInUse } from './payments';
import { AvailabilityRange } from 'common/types/availability';
import {
	getOpeningTimesForDate,
	getOpeningTimesForDateWithMoments,
	getOpeningHoursBasedOnLocation,
	getExceptionPeriodsBasedOnLocation,
} from 'common/utils/shopUtils';
import moment from 'moment-timezone';
import { TFunction } from 'i18next';
import { ShopOnlinePaymentMethodObject } from 'common/modules/payments/types';
import {
	ItemPricingWithoutCurrency,
	getTotalItemPricing,
	ItemPricing,
} from 'common/modules/atoms/pricing';
import { notNull } from './common';
import { getItemPricingFromListPrice } from 'common/modules/atoms/pricing';

export enum ExtensionInvalidReason {
	/**
	 * Not all products have extensions enabled
	 */
	EXTENSIONS_NOT_ENABLED_FOR_PRODUCTS = 'EXTENSIONS_NOT_ENABLED_FOR_PRODUCTS',

	/**
	 * Extensions are not enabled for shop
	 */
	EXTENSIONS_NOT_ENABLED_FOR_SHOP = 'EXTENSIONS_NOT_ENABLED_FOR_SHOP',

	/**
	 * Extension length is zero
	 */
	EXTENSION_LENGTH_ZERO = 'EXTENSION_LENGTH_ZERO',

	/**
	 * Extension price is zero
	 */
	EXTENSION_PRICE_ZERO = 'EXTENSION_PRICE_ZERO',

	/**
	 * Hourly extensions not available (only daily)
	 */
	HOURLY_EXTENSION_NOT_POSSIBLE = 'HOURLY_EXTENSION_NOT_POSSIBLE',

	/**
	 * Extension ends outside shop opening hours
	 */
	SHOP_NOT_OPEN = 'SHOP_NOT_OPEN',

	/**
	 * Extension length is more than the maximum allowed
	 */
	MAX_DATE_EXCEEDED = 'MAX_DATE_EXCEEDED',

	/**
	 * No extensions are possible due to product availability
	 */
	NO_EXTENSIONS_AVAILABLE = 'NO_EXTENSIONS_AVAILABLE',

	/**
	 * The specific extension is not possible due to product availability
	 */
	NOT_AVAILABLE = 'NOT_AVAILABLE',

	// Delivery orders with pickup cannot be extended

	HAS_DELIVERY_PICKUP = 'HAS_DELIVERY_PICKUP',
}

export type ExtensionValidationResult =
	| {
			isValid: false;
			reason: ExtensionInvalidReason.SHOP_NOT_OPEN;
			meta: {
				endDate: moment.Moment;
				openingTimes: OpeningHourTimesWithMoments;
			};
	  }
	| {
			isValid: false;
			reason: ExtensionInvalidReason.MAX_DATE_EXCEEDED;
			meta: {
				endDate: moment.Moment;
				maxDate: moment.Moment;
			};
	  }
	| {
			isValid: false;
			reason: ExtensionInvalidReason.NOT_AVAILABLE;
			meta: {
				endDate: moment.Moment;
				maxDate: moment.Moment;
			};
	  }
	| {
			isValid: false;
			reason:
				| ExtensionInvalidReason.EXTENSION_PRICE_ZERO
				| ExtensionInvalidReason.EXTENSION_LENGTH_ZERO
				| ExtensionInvalidReason.NO_EXTENSIONS_AVAILABLE
				| ExtensionInvalidReason.HOURLY_EXTENSION_NOT_POSSIBLE
				| ExtensionInvalidReason.EXTENSIONS_NOT_ENABLED_FOR_SHOP
				| ExtensionInvalidReason.EXTENSIONS_NOT_ENABLED_FOR_PRODUCTS
				| ExtensionInvalidReason.HAS_DELIVERY_PICKUP;
	  }
	| {
			isValid: false;
			reason: ExtensionInvalidReason.HAS_DELIVERY_PICKUP;
	  }
	| {
			isValid: true;
	  };

/** Get the price of a specific extension
 *
 * @param extensionPricing An extension pricing object
 * @param extensionDuration The duration of the extension
 */
export const getExtensionPriceFromPriceObject = (
	extensionPricing: ExtensionBasePrice,
	extensionDuration: ExtensionDuration,
): number | null => {
	if (!extensionPricing.enabled) return null;
	const { hours, days } = extensionDuration;
	const { pricePerHour, pricePerDay } = extensionPricing;

	return hours * pricePerHour + days * pricePerDay;
};

/** Get the end date of a specific extension
 *
 * @param extensionStartDate The start date of the extension
 * @param extensionDuration The duration of the extension
 */
export const getExtensionEndDate = (props: {
	extensionStartDate: moment.Moment;
	extensionDuration: ExtensionDuration;
}): moment.Moment => {
	return moment(props.extensionStartDate)
		.add(props.extensionDuration.days, 'days')
		.add(props.extensionDuration.hours, 'hours');
};

/** Get the price of extending a given array of OrderProducts for a set duration
 *
 * @param products An array of OrderProducts
 * @param stockProducts The corresponding ProductApis
 * @param extensionDuration The duration of the extension
 */
export const getExtensionPrice = (
	stockProduct: ProductApi,
	extensionDuration: ExtensionDuration,
): number | null => {
	const priceObject = getExtensionBasePrice(stockProduct);
	return getExtensionPriceFromPriceObject(priceObject, extensionDuration);
};

/** Get the pricing of extending a given array of OrderProducts for a set duration
 *
 * @param products An array of OrderProducts
 * @param stockProducts The corresponding ProductApis
 * @param extensionDuration The duration of the extension
 */
export const getTotalExtensionPricing = (
	products: OrderProduct[],
	stockProducts: ProductApi[],
	extensionDuration: ExtensionDuration,
): ItemPricing | ItemPricingWithoutCurrency | null => {
	const stockProductsById = hashByUniqueField(stockProducts, 'id');
	const extensionPricings = products.map((product) => {
		const stockProduct = stockProductsById[product.productApiId];
		return getExtensionPricing(stockProduct, extensionDuration, {
			taxExcluded: product.pricing.taxExcluded,
			currency: product.pricing.currency,
		});
	});
	if (extensionPricings.some((pricing) => pricing == null)) {
		return null;
	}
	const totalPricing = getTotalItemPricing(extensionPricings.filter(notNull));
	return totalPricing;
};

export const getExtensionPricing = (
	stockProduct: ProductApi,
	extensionDuration: ExtensionDuration,
	opts: { taxExcluded: boolean; currency: Currency },
): ItemPricing | null => {
	const priceObject = stockProduct ? getExtensionBasePrice(stockProduct) : undefined;
	if (!priceObject || !priceObject.enabled) return null;
	const totalPrice = getExtensionPriceFromPriceObject(priceObject, extensionDuration);
	if (!totalPrice) return null;
	const pricing = getItemPricingFromListPrice(totalPrice, {
		taxRate: stockProduct.vatPercent / 100,
		taxExcluded: opts.taxExcluded,
		currency: opts.currency,
	});
	return pricing;
};

/** Get the extension pricing object for a product. This can be used to
 * calculate the price of an extension for a given duration.
 *
 * This can also be used to check if a product is valid for extension,
 * as the @enabled property in the result will be false if not.
 *
 * @param product OrderProduct
 * @param stockProduct The corresponding ProductApi
 */
export const getExtensionBasePrice = (stockProduct: ProductApi): ExtensionBasePrice => {
	const extensionPricing = stockProduct.extensionPricing;
	const isFixedPriceProduct = !stockProduct.pricing?.length && !stockProduct.useSavedPricingTable;

	if (extensionPricing?.enabled !== true && isFixedPriceProduct) {
		/**
		 * Special case for fixed price products where no price is defined (allow them to be extended with price 0).
		 */
		return {
			enabled: true,
			pricePerHour: 0,
			pricePerDay: 0,
		};
	}

	if (
		!extensionPricing ||
		!extensionPricing.enabled ||
		(!extensionPricing.pricePerHour && !extensionPricing.pricePerDay)
	) {
		/**
		 * For other types of products
		 */
		return {
			enabled: false,
			pricePerHour: 0,
			pricePerDay: 0,
		};
	}

	return {
		enabled: true,
		pricePerHour: extensionPricing.pricePerHour,
		pricePerDay: extensionPricing.pricePerDay || 24 * extensionPricing.pricePerHour,
	};
};

/** Get the combined extension pricing object for an array of products. This can be used to
 * calculate the price of an extension for a given duration.
 *
 * This can also be used to check if an array of products is valid for extension,
 * as the @enabled property in the result will be false if not.
 *
 * @param products An array of OrderProducts
 * @param stockProducts The corresponding ProductApis
 */
export const getTotalExtensionBasePrice = (
	products: OrderProduct[],
	stockProducts: ProductApi[],
): ExtensionBasePrice => {
	const stockProductsById = hashByUniqueField(stockProducts, 'id');

	if (!products.length) {
		return { enabled: false, pricePerHour: 0, pricePerDay: 0 };
	}

	const initialValue: ExtensionBasePrice = {
		enabled: true,
		pricePerHour: 0,
		pricePerDay: 0,
	};

	let allProductsHaveHourlyPrice = true;
	const totalPricing = products.reduce((prev, product) => {
		if (!prev.enabled) return prev;
		const stockProduct = stockProductsById[product.productApiId];
		const priceObject = stockProduct ? getExtensionBasePrice(stockProduct) : undefined;
		if (!priceObject || !priceObject.enabled) {
			const result: ExtensionBasePrice = { enabled: false, pricePerHour: 0, pricePerDay: 0 };
			return result;
		}
		if (!priceObject.pricePerHour) {
			allProductsHaveHourlyPrice = false;
		}
		return {
			...prev,
			pricePerHour: prev.pricePerHour + priceObject.pricePerHour,
			pricePerDay: prev.pricePerDay + priceObject.pricePerDay,
		};
	}, initialValue as ExtensionBasePrice);

	return allProductsHaveHourlyPrice
		? totalPricing
		: {
				...totalPricing,
				pricePerHour: 0,
		  };
};

/** Get the last possible date when an extension can end, in terms of availability. If availability is not an issue,
 * returns the max end date as per the limit for a single extension duration (MAX_EXTENSION_SECONDS)
 *
 * @param extensionStartDate The date when the extension would start
 * @param availabilityRanges Availability ranges for the duration of the extension
 */
export const getExtensionMaxDate = (props: {
	extensionStartDate: moment.Moment;
}): moment.Moment => {
	return moment(props.extensionStartDate).add(MAX_EXTENSION_SECONDS, 'seconds');
};

/** Check that a shop is valid for extensions
 *
 * @param props
 */
export const isShopValidForExtension = (props: {
	hasExtensionFeature: boolean; // This is passed as parameter, as it needs to be fetched from server side
	activePaymentMethods: ShopOnlinePaymentMethodObject[];
}): boolean => {
	const { hasExtensionFeature, activePaymentMethods } = props;
	const rentlePaymentsInUse = isRentlePaymentsInUse(activePaymentMethods);
	return Boolean(hasExtensionFeature && rentlePaymentsInUse);
};

/** Check that all products can be extended
 *
 * @param products The OrderProducts included in the order
 * @param stockProducts The corresponding ProductApis
 * @param hasExtensionFeature: Boolean value if the shop has the extension feature toggled on
 * @param shopPaymentMethods: Active payment methods of the shop
 * @param hasDeliveryPickUp: Boolean value if delivery has pickup assinged
 */
export const isOrderValidForExtension = async (props: {
	products: OrderProduct[];
	stockProducts: ProductApi[];
	hasExtensionFeature: boolean; // This is passed as parameter, as it needs to be fetched from server side
	activePaymentMethods: ShopOnlinePaymentMethodObject[];
	hasDeliveryPickUp: boolean;
}) => {
	const {
		products,
		stockProducts,
		activePaymentMethods,
		hasExtensionFeature,
		hasDeliveryPickUp,
	} = props;

	const shopValidForExtension = isShopValidForExtension({
		activePaymentMethods,
		hasExtensionFeature,
	});
	const extensionEnabledForProducts = isExtensionEnabledForProducts({
		products,
		stockProducts,
	});
	const extensionEnabledForOrder = !hasDeliveryPickUp;
	return shopValidForExtension && extensionEnabledForProducts && extensionEnabledForOrder;
};

export const isExtensionEnabledForProducts = (props: {
	products: OrderProduct[];
	stockProducts: ProductApi[];
}): boolean => {
	const { products, stockProducts } = props;
	if (!allProductsHaveSameEndDate(products)) return false;
	const pricing = getTotalExtensionBasePrice(products, stockProducts);

	if (!pricing.enabled || (pricing.pricePerHour === 0 && pricing.pricePerDay === 0)) {
		return false;
	}
	return true;
};

/** Check that the shop is open when the extension ends
 *
 * @param extensionEndDate The end date of the extension
 * @param openingHours The opening hours (with exception periods) of the shop
 */
export const isShopOpenOnExtensionEnd = (props: {
	extensionEndDate: moment.Moment;
	openingHours: OpeningHoursWithExceptions;
}) => {
	const openingTimes = getOpeningTimesForDate(props.extensionEndDate, props.openingHours);

	if (!openingTimes) return true;

	if (openingTimes.isClosed) return false;

	const [openHours, openMinutes] = openingTimes.openTime.split(':').map((i) => Number(i));
	const openTime = moment(props.extensionEndDate)
		.startOf('day')
		.hours(openHours)
		.minutes(openMinutes);
	if (openTime.isAfter(props.extensionEndDate)) {
		return false;
	}

	const [closeHours, closeMinutes] = openingTimes.closeTime.split(':').map((i) => Number(i));
	const closeTime = moment(props.extensionEndDate)
		.startOf('day')
		.hours(closeHours)
		.minutes(closeMinutes);
	if (props.extensionEndDate.isAfter(closeTime)) {
		return false;
	}

	return true;
};

export const getMaxAvailabilityDate = (props: {
	availabilityRanges: AvailabilityRange[];
}): moment.Moment => {
	const firstUnavailableRange = props.availabilityRanges.find(
		(range) => range.lowestAvailabilityWithCount < 0,
	);

	return firstUnavailableRange ? moment(firstUnavailableRange.startDate) : moment().year(3000);
};

/** Check that the extension is valid from an availability standpoint
 *
 * @param extensionEndDate The end date of the extension
 * @param availabilityRanges The availabilityRanges from the current end date up to the extended end date
 */
export const isExtensionAvailable = (props: {
	extensionEndDate: moment.Moment;
	availabilityRanges: AvailabilityRange[];
}): boolean => {
	const maxEndDate = getMaxAvailabilityDate({ availabilityRanges: props.availabilityRanges });

	if (maxEndDate) {
		return props.extensionEndDate.isBefore(maxEndDate);
	}

	return true;
};

/** Validate that an extension length is less than the maximum allowed
 *
 * @param extensionStartDate The start date of the extension
 * @param extensionEndDate The end date of the extension
 * @param maxDurationInSeconds The maximum allowed duration for a single extension, in seconds
 */
export const isExtensionLessThanMax = (props: {
	extensionStartDate: moment.Moment;
	extensionEndDate: moment.Moment;
	maxDurationInSeconds: number;
}): boolean => {
	return !moment(props.extensionStartDate)
		.add(props.maxDurationInSeconds, 'seconds')
		.isBefore(props.extensionEndDate);
};

export const allProductsHaveSameEndDate = (products: OrderProduct[]) => {
	if (products.length === 0) return true;
	const endDates = products.map((p) => p.endDate);
	return endDates.every((date) => endDates[0]);
};

export interface ValidateExtensionProps {
	products: OrderProduct[];
	stockProducts: ProductApi[];
	endLocationId: string;
	shopInfo: ShopPublicInfo;
	exceptionPeriods: ExceptionPeriod[];
	extensionStartDate: moment.Moment;
	extensionDuration: ExtensionDuration;
	availabilityRanges: AvailabilityRange[];
	hasExtensionFeature: boolean;
	TEMP_IGNORE_AVAILABILITY?: boolean;
	hasDeliveryPickUp: boolean;
}

/** Validate that a a given extension can be made
 *
 * @param products An array of OrderProducts to be extended
 * @param stockProducts The corresponding ProductApis
 * @param shopInfo The public info of the shop
 * @param exceptionPeriods The opening hours exception periods of the shop
 * @param extensionStartDate The start date of the extension (current end date of the rental)
 * @param extensionDuration The duration of the extension
 * @param availabilityRanges The availability ranges of the products involved. You can get this with getExtensionAvailabilityRanges().
 * @param hasExtensionFeature: Boolean value if the shop has the extension feature toggled on
 * @param TEMP_IGNORE_AVAILABILITY: Temporary variable for ignoring availability check
 * @param hasDeliveryPickUp: Boolean value if the delivery has pickup assigned
 */
export const validateExtension = (props: ValidateExtensionProps): ExtensionValidationResult => {
	const {
		products,
		stockProducts,
		endLocationId,
		extensionDuration,
		extensionStartDate,
		availabilityRanges,
		shopInfo,
		exceptionPeriods,
		hasExtensionFeature,
		TEMP_IGNORE_AVAILABILITY,
		hasDeliveryPickUp,
	} = props;

	/**
	 * Check that at least the shortest possible extension is possible
	 */
	const maxAvailabilityDate = getMaxAvailabilityDate({
		availabilityRanges,
	});
	const earliestExtensionEndDate = moment(extensionStartDate).add(1, 'hour');

	// Check that Delivery order has pickUp

	if (hasDeliveryPickUp) {
		return {
			isValid: false,
			reason: ExtensionInvalidReason.HAS_DELIVERY_PICKUP,
		};
	}

	if (
		!TEMP_IGNORE_AVAILABILITY &&
		maxAvailabilityDate &&
		earliestExtensionEndDate.isAfter(maxAvailabilityDate)
	) {
		return {
			isValid: false,
			reason: ExtensionInvalidReason.NO_EXTENSIONS_AVAILABLE,
		};
	}

	/**
	 * Check that shop supports extensions
	 */
	if (
		!isShopValidForExtension({
			activePaymentMethods: shopInfo.activePaymentMethods ?? [],
			hasExtensionFeature,
		})
	) {
		return {
			isValid: false,
			reason: ExtensionInvalidReason.EXTENSIONS_NOT_ENABLED_FOR_SHOP,
		};
	}

	/**
	 * Check that all products can be extended
	 */
	const pricing = getTotalExtensionBasePrice(products, stockProducts);

	if (!pricing.enabled) {
		return {
			isValid: false,
			reason: ExtensionInvalidReason.EXTENSIONS_NOT_ENABLED_FOR_PRODUCTS,
		};
	}

	/**
	 * All products must have same end date
	 */
	if (!allProductsHaveSameEndDate(products)) {
		return {
			isValid: false,
			reason: ExtensionInvalidReason.EXTENSIONS_NOT_ENABLED_FOR_PRODUCTS,
		};
	}

	/**
	 * If extending by any amount of hours, check that all products support hourly extensions
	 */
	if (pricing.pricePerHour === 0 && extensionDuration.hours > 0) {
		return {
			isValid: false,
			reason: ExtensionInvalidReason.HOURLY_EXTENSION_NOT_POSSIBLE,
		};
	}

	/**
	 * Check that the price of the extension is more than zero
	 */
	if (pricing.pricePerHour === 0 && pricing.pricePerDay === 0) {
		return {
			isValid: false,
			reason: ExtensionInvalidReason.EXTENSION_PRICE_ZERO,
		};
	}

	/**
	 * Check that the extension length is more than zero
	 */
	if (extensionDuration.hours === 0 && extensionDuration.days === 0) {
		return {
			isValid: false,
			reason: ExtensionInvalidReason.EXTENSION_LENGTH_ZERO,
		};
	}

	/**
	 * Check that extension does not exceed the maximum duration for a single extension (currently 7 days)
	 */
	const extensionEndDate = getExtensionEndDate({
		extensionStartDate,
		extensionDuration,
	});
	if (
		!isExtensionLessThanMax({
			extensionStartDate,
			extensionEndDate,
			maxDurationInSeconds: MAX_EXTENSION_SECONDS,
		})
	) {
		return {
			isValid: false,
			reason: ExtensionInvalidReason.MAX_DATE_EXCEEDED,
			meta: {
				maxDate: getExtensionMaxDate({
					extensionStartDate,
				}),
				endDate: extensionEndDate,
			},
		};
	}

	/**
	 * Check that extension does not end outside hours (for return location)
	 */
	const openingHours = {
		openingHours: getOpeningHoursBasedOnLocation(shopInfo.openingHours, endLocationId),
		exceptionPeriods: getExceptionPeriodsBasedOnLocation(exceptionPeriods, endLocationId),
	};
	if (
		!isShopOpenOnExtensionEnd({
			extensionEndDate,
			openingHours,
		})
	) {
		return {
			isValid: false,
			reason: ExtensionInvalidReason.SHOP_NOT_OPEN,
			meta: {
				openingTimes: getOpeningTimesForDateWithMoments(extensionEndDate, openingHours)!,
				endDate: extensionEndDate,
			},
		};
	}

	/**
	 * Check that the extension is available
	 */
	if (
		!isExtensionAvailable({
			extensionEndDate,
			availabilityRanges,
		}) &&
		!TEMP_IGNORE_AVAILABILITY
	) {
		return {
			isValid: false,
			reason: ExtensionInvalidReason.NOT_AVAILABLE,
			meta: {
				maxDate: maxAvailabilityDate,
				endDate: extensionEndDate,
			},
		};
	}

	return {
		isValid: true,
	};
};

/** Get a very simple "best guess" of appropriate defaults for extension pricing
 *
 * @param fixedPrice The fixed price (base price) of the product
 */
export const getDefaultExtensionPricing = (fixedPrice?: number): ExtensionBasePrice => {
	return {
		enabled: false,
		pricePerHour: fixedPrice ?? 0,
		pricePerDay: fixedPrice ? fixedPrice * 8 : 0,
	};
};

export const getExtensionDurationString = (
	duration: ExtensionDuration,
	t: TFunction,
	prefix?: string,
): string | null => {
	const text = [duration.days, duration.hours]
		.map((value, index) => {
			if (!value) return '';
			switch (index) {
				case 0:
					return `${value} ${t('common:times.days', 'days')}`;
				case 1:
					return `${value} ${t('common:times.hours', 'hours')}`;
				default:
					return '';
			}
		})
		.filter((v) => v !== '')
		.join(', ');

	if (text) {
		return (prefix ?? '') + text;
	}
	return null;
};

export const getExtensionDurationShortString = (
	duration: ExtensionDuration,
	t: TFunction,
	prefix?: string,
): string | null => {
	const text = [duration.days, duration.hours]
		.map((value, index) => {
			if (!value) return '';
			switch (index) {
				case 0:
					return `${value}${t('common:times.daysAbbreviation', 'd')}`;
				case 1:
					return `${value}${t('common:times.hoursAbbreviation', 'h')}`;
				default:
					return '';
			}
		})
		.filter((v) => v !== '')
		.join(' ');

	if (text) {
		return (prefix ?? '') + text;
	}
	return null;
};

export const getExtensionLink = (
	orderId: string,
	env: 'live' | 'test',
	options?: { short: boolean },
) => {
	return `${env === 'live' ? 'https://app.rentle.io' : 'https://dev.app.rentle.io'}/${
		options?.short ? 'o' : 'order'
	}/${orderId}`;
};
