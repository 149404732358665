import { Scopes } from 'common/constants/api';
import type { Order, Product } from 'common/apiModels/';
import type { Timestamp } from 'common/frontend/firebase/firestore';

export type ApiKeyInfo = {
	label: string;
	hash: string;
	keyId: string;
	shopId: string;
	created: Timestamp;
	scopes: Scopes[];
};

export type ApiKeyStatsDay = {
	keyId: string;
	date: string;
	calls: number;
	updated: Timestamp;
};

export enum WebhookEvent {
	ORDER_CREATED = 'ORDER_CREATED',
	ORDER_UPDATED = 'ORDER_UPDATED',
	ORDER_PREPARED = 'ORDER_PREPARED',
	ORDER_STARTED = 'ORDER_STARTED',
	ORDER_RETURNED = 'ORDER_RETURNED',
	PRODUCT_CREATED = 'PRODUCT_CREATED',
	PRODUCT_UPDATED = 'PRODUCT_UPDATED',
	PRODUCT_DELETED = 'PRODUCT_DELETED',
}

export type WebhookInfo = {
	id: string;
	label: string;
	urls: string[];
	event: WebhookEvent;
};

export type WebhookStatsDay = {
	webhookId: string;
	date: string;
	calls: number;
	updated: Timestamp;
};

export type WebhookData =
	| {
			event:
				| WebhookEvent.ORDER_CREATED
				| WebhookEvent.ORDER_UPDATED
				| WebhookEvent.ORDER_PREPARED
				| WebhookEvent.ORDER_STARTED
				| WebhookEvent.ORDER_RETURNED;
			payload: Order;
	  }
	| {
			event:
				| WebhookEvent.PRODUCT_CREATED
				| WebhookEvent.PRODUCT_UPDATED
				| WebhookEvent.PRODUCT_DELETED;
			payload: Product;
	  };
