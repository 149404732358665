import { ProductApi } from 'common/types';
import { PhotoUploadedType, SkidataTicketTimePeriodType } from 'common/modules/skidata';
import { GetSkidataTicketsPricesArgs } from 'common/api/db/functions/_callable/skidata';
export * from 'common/api/db/functions/_callable/skidata';
export interface SkidataCredentials {
	skidataClientName: string;
	skidataUsername: string;
	skidataPassword: string;
}

export interface SkidataIntegration extends SkidataCredentials {
	lastSynced?: string;
}

export interface SkidataOrder {
	orderId: string;
	orderItems: SkidataOrderItem[];
	date: string;
	totalPrice: {
		amount: string;
		currencyCode: string;
	};
	confirmationNumber: string;
	contactId?: string;
}
export interface SkidataOrderItem {
	id: string;
	productId: string;
	segment: string;
	ticketItems: { id: string; contactId?: string; liftKeycardNumber?: string }[];
	orderStatus: string;
	orderType: string;
	validFrom: string;
	quantity: string;
	unitPrice: {
		amount: string;
		currencyCode: string;
	};
	packageId?: string;
	packageProductId?: string;
}
export interface SkidataCancelledOrder {
	cancelationOrderId: string;
	cancelationSystemDate: string;
	originalOrderId?: string;
}
export interface SkidataProductApi extends ProductApi {
	additionalKeycardProducts?: { consumerCategoryId: string; productId: string }[];
}

export interface SkidataPackageProductApi extends ProductApi {
	minItemQuantity: number;
	packageItems: {
		externalId: string;
		quantity: {
			min: number;
			max: number;
		};
		externalSegmentId: string;
	}[];
}

export interface SkidataProps {
	orderId: string;
	confirmationNumber: string;
	DTAOrderCharge: number;
	DTAOrderStatus?: DTAOrderStatus;
	cancellationOrderId?: string;
}

export enum SkidataKeycardType {
	NEW = 'NEW_KEYCARD',
	EXISTING = 'EXISTING_KEYCARD',
}

export enum DTAOrderStatuses {
	RESERVED = 'Reserved',
	BOOKED_AND_TRANSFERRED = 'BookedAndTransferred',
	//BOOKED = 'Booked', //This status exists in DTA but we don't currently use it
	MISSING_KEYCARDS = 'MissingKeycards',
	MISSING_PHOTOS = 'MissingPhotos',
	CANCELLED = 'Cancelled',
	BOOKED_AND_REJECTED = 'BookedAndRejected',
}

export type DTAOrderStatus = DTAOrderStatuses;

export interface ShopperIdWithPhotoUploaded {
	shopperId: string;
	photoUploaded: PhotoUploadedType;
}

export interface GetSkidataTicketPriceArgs extends Omit<GetSkidataTicketsPricesArgs, 'productIds'> {
	productId: string;
}

export interface SkidataTicketValidity {
	type: SkidataTicketTimePeriodType;
	from: string;
	to: string;
}
