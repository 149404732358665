import { getTypedValues, stringEnum } from 'common/utils/objects';

export const CookieScriptCategories = stringEnum([
	'strict',
	'performance',
	'targeting',
	'functionality',
	'unclassified',
]);

export const allCookieScriptCategories = getTypedValues(CookieScriptCategories);

export const CookieParameters = {
	strict: 'nec',
	functionality: 'pre',
	performance: 'sta',
	targeting: 'mar',
	unclassified: 'unc',
} as const;
