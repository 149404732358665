import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import Main from './Main';

const environment = process.env.REACT_APP_ENV;
const Online: React.FC = () => {
	const classes = useStyles();
	return (
		<div id={'indexRoot'} className={classNames(classes.root)}>
			<Switch>
				<Route
					exact
					path={`/`}
					render={() => {
						if (environment === 'development') {
							window.location.replace(`https://dev.my.rentle.shop/`);
							return null;
						} else if (environment === 'production') {
							window.location.replace(`https://my.rentle.shop/`);
							return null;
						} else {
							window.location.replace(`https://dev.my.rentle.shop/`);
							return null;
						}
					}}
				/>
				{/** Temporary redirect to register page to handle old domain changes, Toomas/02-02-20201 */}
				<Route
					exact
					path={`/register`}
					render={() => {
						if (environment === 'development') {
							window.location.replace(`https://dev.my.rentle.shop/register`);
							return null;
						} else if (environment === 'production') {
							window.location.replace(`https://my.rentle.shop/register`);
							return null;
						} else {
							window.location.replace(`https://dev.my.rentle.shop/register`);
							return null;
						}
					}}
				/>
				<Route path="/:shopName" component={Main} />
			</Switch>
		</div>
	);
};

const useStyles = makeStyles((theme) => ({
	root: {
		height: '100%',
	},
	popupOpen: {
		overflow: 'hidden',
	},
}));

export default Online;
