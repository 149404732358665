export const getFormattedUrlPath = (urlString: string) => {
	const urlStringParts = urlString.split('/');
	return urlStringParts.map((part) => (isDatabaseId(part) ? 'ID' : part)).join('/');
};

/**
 * Naive implementation trying to identify if a string is database ID
 * Checks if
 *  - string length is 20 chars
 *  - at least 2 uppercase characters (>99.9% probability to have this if DB ID)
 */
export const isDatabaseId = (textString: string): boolean => {
	if (textString.length !== 20) {
		return false;
	}
	const minTwoUppercaseLetters = new RegExp('[A-Z].*[A-Z]');
	if (!minTwoUppercaseLetters.test(textString)) {
		return false;
	}
	return true;
};
