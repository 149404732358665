import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { redirectToNewOnline } from 'utils/newOnlineUrlUtils';

const Main: FC = () => {
	const { shopName } = useParams<{ shopName: string }>();
	redirectToNewOnline(shopName);
	return null;
};

export default Main;
