import { createBrowserHistory } from 'history';
import * as Analytics from 'common/services/analytics';

const history = createBrowserHistory();

// For first pageview that is not tracked ly "listen" event
const setInitialPageView = () => {
	// Add timeout so Firebase analytics have time to initialize
	setTimeout(() => {
		Analytics.pageView(window.location.pathname, 'ONLINE');
	}, 2500);
};

history.listen((location, action) => {
	Analytics.pageView(location.pathname, 'ONLINE');
});

setInitialPageView();

export default history;
