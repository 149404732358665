import {
	AtLeast,
	LocaleField,
	BookingBuffer,
	ProductApi,
	OpeningHoursDayType,
	OrderPayment,
} from 'common/types';
import { ItemPricing } from '../atoms/pricing';
import { stringEnum } from 'common/utils/objects';

export interface OrderDeliveryService {
	id: string;
	shopId: string;
	orderId: string;
	name: LocaleField;
	pricing: ItemPricing;
	charge: OrderPayment;
	createdAt: string;
	deliveryOptionId: string;
	to?: OrderDeliveryDetails;
	from?: OrderDeliveryDetails;
	availablePickupSlots?: DeliveryTimeSlot[];
}
export interface OrderDeliveryDetails {
	timeslot: DeliveryTimeSlot | null;
	pricing: ItemPricing;
	handlingTimeMinutes: number;
	location: {
		details: string | null;
		/* To be extended with address in the future */
	};
	carrier?: {
		id: string;
		name: LocaleField;
	};
	disabled?: boolean;
}

export type OrderDelivery = OrderDeliveryService;

export type CartDelivery = AtLeast<OrderDelivery, 'id' | 'name' | 'pricing' | 'deliveryOptionId'>;

export const DeliveryTypes = stringEnum(['DELIVERY_AND_PICKUP', 'DELIVERY_ONLY']);
export type DeliveryType = keyof typeof DeliveryTypes;

export interface DeliveryOption {
	id: string;
	name: LocaleField;
	type?: DeliveryType;
	description: LocaleField;
	vatPercent: number;
	minimalDurationInSeconds: number;
	locationIds: string[];
	bookingBuffer: BookingBuffer;
	shopId: string;
	createdAt: string;
	deliveryPeriods: DeliveryPeriod[];
	price: {
		delivery: number;
		pickup: number;
	};
	handlingTimeMinutes: {
		before: number;
		after: number;
	};
	hidden?: boolean;
	returnInstructions?: LocaleField;
}

export type DeliveryOptionWithProducts = DeliveryOption & {
	products: ProductApi[];
};

export interface OrderInfoDelivery {
	orderDeliveryId: string;
	to?: {
		startTime: string;
		carrierId?: string;
	};
	from?: {
		endTime: string;
		carrierId?: string;
	};
}
export interface DeliveryPeriod {
	startDate: string;
	endDate: string | null;
	times: DeliveryTime[];
}

export interface DeliveryTime {
	dayType: OpeningHoursDayType;
	slots: DeliveryTimeSlot[];
}

export interface DeliveryTimeSlot {
	startTime: string;
	endTime: string;
}

export interface Carrier {
	id: string;
	name: LocaleField;
	locationIds: string[];
	shopId: string;
	createdAt: string;
}
