import { Components } from '@mui/material/styles';
import { merge } from 'lodash';

import * as Fonts from './fonts';
import * as Colors from './colors';

interface OverrideOptions {
	fontFamily?: string;
	extras?: Components;
}

export const defaultOptions: Required<OverrideOptions> = {
	fontFamily: Fonts.inter,
	extras: {},
};

export const getOverrides = (options: OverrideOptions = defaultOptions): Components => {
	const bodyFont = options.fontFamily ?? defaultOptions.fontFamily;
	const base: Components = {
		MuiCssBaseline: {
			styleOverrides: {
				'*': {
					fontFamily: bodyFont,
				},
				html: {
					boxSizing: 'border-box',
					height: '100%',
					width: '100%',
					fontSize: '62.5%',
					'&.large-font': {
						fontSize: '68.75%',
					},
				},
				'*, *:before, *:after': {
					boxSizing: 'inherit',
				},
				body: {
					margin: 0,
					padding: 0,
					height: '100%',
					width: '100%',
					touchAction: 'manipulation',
					fontSize: '1.4rem',
					fontWeight: 400,
				},
				'#root': {
					width: '100%',
					height: '100%',
				},
				a: {
					color: 'inherit',
					textDecoration: 'none',
				},
				'.MuiTypography-root a': {
					fontWeight: 500,
				},
			},
		},
		MuiFab: {
			styleOverrides: {
				root: {
					borderRadius: 50,
				},
				extended: {
					borderRadius: 50,
				},
			},
		},
		// https://github.com/mui-org/material-ui/issues/17701
		MuiMenuItem: {
			styleOverrides: {
				root: {
					minHeight: '48px !important',
				},
			},
		},
		MuiTypography: {
			styleOverrides: {
				body2: {
					fontWeight: 500,
				},
			},
		},
		MuiFormLabel: {
			styleOverrides: {
				root: {
					fontWeight: 500,
				},
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontWeight: 500,
				},
			},
		},
		MuiLink: {
			styleOverrides: {
				root: {
					fontWeight: 500,
				},
			},
		},
		MuiFormHelperText: {
			styleOverrides: {
				root: {
					fontWeight: 500,
				},
			},
		},
		MuiListItemText: {
			styleOverrides: {
				primary: {
					fontWeight: 500,
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				root: {
					borderRadius: 50,
					minWidth: 112,
					minHeight: 40,
					fontWeight: 700,
				},
				outlined: {
					borderWidth: '2px !important',
				},
			},
		},
		MuiStepLabel: {
			styleOverrides: {
				root: {
					fontWeight: 500,
				},
			},
		},
		MuiAlert: {
			styleOverrides: {
				standardInfo: {
					background: Colors.indigo.light,
					color: Colors.black.light,
					'& .MuiAlert-icon': {
						color: Colors.indigo.main,
					},
				},
				standardError: {
					background: Colors.tazz.light,
					'& .MuiAlert-icon': {
						color: Colors.tazz.main,
					},
					color: Colors.black.main,
				},
			},
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					background: Colors.black.main,
					fontSize: '1.4rem',
				},
				arrow: {
					color: Colors.black.main,
				},
			},
		},
	};
	return merge({}, base, options.extras);
};
