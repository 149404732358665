import {
	CookieScriptCategories,
	CookieScriptCategory,
	CookieScriptService,
	CookieScriptState,
} from 'common/services/cookieConsent';

const CookieQueue: Record<CookieScriptCategory, any[]> = {
	[CookieScriptCategories.strict]: [],
	[CookieScriptCategories.performance]: [],
	[CookieScriptCategories.targeting]: [],
	[CookieScriptCategories.functionality]: [],
	[CookieScriptCategories.unclassified]: [],
};

export const executeWhenConsent = (
	consent: CookieScriptCategory,
	action: (...args: any[]) => any,
) => {
	const CookieScript = (window as any)?.CookieScript as CookieScriptService | undefined;
	// CookieScript always exists if we are using it to block cookies.
	// If this is called from a file that doesn't use CookieScript, we want to execute it directly
	if (!CookieScript) {
		action();
		return;
	}
	const currentConsent = CookieScript.instance.currentState();
	if (currentConsent.categories.includes(consent)) {
		action();
	} else {
		CookieQueue[consent].push(action);
	}
};

export const executeCookieQueue = (consent: CookieScriptState) => {
	const consentCategories = consent.categories;
	consentCategories.forEach((category) => {
		CookieQueue[category].forEach((action) => action());
		CookieQueue[category] = [];
	});
};
