import React, { useEffect } from 'react';
import { Theme } from '@mui/material';
import { WithStyles } from '@mui/styles';
import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import makeStyles from '@mui/styles/makeStyles';
import Fade from '@mui/material/Fade';
import CircularProgress from '@mui/material/CircularProgress';
import classNames from 'classnames';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import useTimeout from 'common/hooks/useTimeout';

interface Props {
	in?: boolean;
	size?: number;
	color?: string;
	padding?: number;
	thickness?: number;
	relative?: boolean;
	margin?: number;
	text?: string;
	bgColor?: string;
	renderDelayMs?: number;
}

const Spinner = (props: Props) => {
	const renderDelayMs = props.renderDelayMs ?? 0;
	const classes = useStyles();
	const [visible, setVisible] = useState<boolean>(renderDelayMs > 0 ? false : true);

	useEffect(() => {
		if (!!renderDelayMs) {
			const renderDelayTimer = setTimeout(() => setVisible(true), renderDelayMs);
			return () => {
				clearTimeout(renderDelayTimer);
			};
		}
		return () => undefined;
	}, [renderDelayMs]);

	return !visible ? null : (
		<div className={classes.container}>
			<div
				className={classNames(classes.wrapper, props.relative ? classes.relative : {})}
				style={{
					backgroundColor: props.bgColor || 'none',
				}}
			>
				<Fade
					in={props.in ? props.in : true}
					style={{
						transitionDelay: props.in ? '0ms' : '0ms',
					}}
					unmountOnExit
				>
					<CircularProgress
						style={{
							width: props.size ? props.size : '100%',
							height: props.size ? props.size : '100%',
							padding: props.padding ? props.padding : '',
							margin: props.margin ? props.margin : '',
							color: props.color ? props.color : '',
						}}
						thickness={props.thickness ? props.thickness : 3.6}
						classes={{
							svg: classes.svg,
							root: classes.spinner,
						}}
					/>
				</Fade>
				{props.text ? (
					<Typography
						className={
							props.size && props.size < 32 ? classes.spinnerTextSmall : classes.spinnerTextRegular
						}
						variant="body2"
					>
						{props.text}
					</Typography>
				) : null}
			</div>
		</div>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			minWidth: 24,
			minHeight: 24,
		},
		wrapper: {
			position: 'absolute',
			width: '100%',
			height: '100%',
			left: 0,
			top: 0,
			backgroundColor: 'inherit',
			zIndex: 10,
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			flexDirection: 'column',
		},
		relative: {
			position: 'relative',
		},
		spinner: {
			maxWidth: 50,
			maxHeight: 50,
		},
		svg: {
			width: '100%',
			height: '100%',
		},
		spinnerTextRegular: {
			marginTop: theme.spacing(2),
		},
		spinnerTextSmall: {
			fontSize: '1.1rem',
			marginTop: theme.spacing(1),
		},
	}),
);

export default Spinner;
