import { stringEnum } from 'common/utils/objects';

export enum ProductTypes {
	RENTAL = 'rental',
	SERVICE = 'service',
	ACCESS = 'access',
}

export const UnitSystems = stringEnum(['METRIC', 'US', 'UK']);

export type UnitSystemType = keyof typeof UnitSystems;

export const UserDetailNames = stringEnum([
	'height',
	'weight',
	'shoeSize',
	'experienceLevel',
	'age',
	'stance',
]);

export type UserDetailName = keyof typeof UserDetailNames;

export const SizeChart = stringEnum(['MEN', 'WOMEN', 'KIDS', 'ADULTS']);

export type SizeChartType = keyof typeof SizeChart;
