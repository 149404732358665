import { stringEnum } from 'common/utils/objects';

export const USER_DIDNT_INPUT_KEYCARD = 'USER_DIDNT_INPUT_KEYCARD';

export const SkidataErrors = stringEnum([
	'DEFAULT_ERROR',
	'NO_INTEGRATION_ERROR',
	'CATALOGUE_UPDATE_ERROR',
	'PRODUCT_LIST_ERROR',
	'UPDATE_TICKET_PRODUCTS_ERROR',
	'UPDATE_TICKET_CATEGORY_ERROR',
	'NO_TICKETS_FOUND_FOR_ORDER_ERROR',
	'ORDER_CREATION_ERROR',
	'MISSING_ORDER_PROPERTIES_ERROR',
	'TICKET_PURCHASE_ERROR',
	'DTA_CREDENTIALS_ERROR',
	'DTA_ERROR',
	'DTA_INVENTORY_ERROR',
	'DTA_CONTACT_ERROR',
	'DTA_SALES_ERROR',
	'DTA_PERMISSION_ERROR',
	'LOAD_PHOTO_ERROR',
]);

export type SkidataErrorsType = keyof typeof SkidataErrors;
