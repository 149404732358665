import { Api } from 'common/db/api/paths';
import moment from 'moment';

export const updateItemActiveOrders = (
	api: Api,
	args: {
		itemId: string;
		orderId: string;
		state: 'IN' | 'OUT';
	},
) => {
	const { itemId, orderId, state } = args;
	return api.inventoryItems.doc(itemId).update({
		...(state === 'OUT' && { lastUsed: moment().toISOString() }),
		activeOrderIds:
			state === 'OUT' ? api.FieldValues.arrayUnion(orderId) : api.FieldValues.arrayRemove(orderId),
	});
};
