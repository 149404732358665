import { executeWhenConsent } from 'common/frontend/helpers/CookieQueue';
import { browserSupportsIndexedDB, runningJestTests } from 'common/utils/browserUtils';
import { FirebasePerformanceMock } from './perfMock';
import {
	getPerformance,
	initializePerformance,
	FirebasePerformance,
	trace,
} from 'firebase/performance';
import { RemoveFirstFromTuple, RemoveFirstFunctionParameter } from 'common/types/utils';
import { firebaseApp } from './app';
import { CookieScriptCategories } from 'common/services/cookieConsent';

export interface Performance {
	configs: Omit<FirebasePerformance, 'app'>;
	trace: RemoveFirstFunctionParameter<typeof trace>;
	isMock: boolean;
}

// Check if Firebase performance supported
// From: https://github.com/firebase/firebase-js-sdk/blob/master/packages/performance/src/services/api_service.ts#L115
// Added check for running jest tests
const isPerformanceSupported = () => {
	if (
		runningJestTests() ||
		!fetch ||
		!Promise ||
		!navigator ||
		!navigator.cookieEnabled ||
		!browserSupportsIndexedDB()
	) {
		return false;
	}
	return true;
};

export let perf: Performance = FirebasePerformanceMock;

export const initPerformance = () => {
	if (isPerformanceSupported()) {
		executeWhenConsent(CookieScriptCategories.performance, () => {
			const perfInstance =
				!perf || perf.isMock
					? initializePerformance(firebaseApp, {
							dataCollectionEnabled: true,
							instrumentationEnabled: true,
					  })
					: getPerformance(firebaseApp);
			perf = {
				configs: perfInstance,
				trace: (...args: RemoveFirstFromTuple<Parameters<typeof trace>>) =>
					trace(perfInstance, ...args),
				isMock: false,
			};
		});
	}
};
